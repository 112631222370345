import { ref } from "vue"
import { UUID } from "@avvoka/shared"
import type { V } from '@component-utils/types';
import { useErrorToast } from "@component-utils/toasts";

export const getUUID = () => {
  /**
   * Lazy fix for crypto#randomUUID not available for localhost when aliased
   */
  if (import.meta.env.PROD) {
    return window.crypto.randomUUID()
  } else {
    return UUID.new()
  }
}

export function useElementId(id: string | undefined) {
  return ref(id || `el-${getUUID()}`)
}

export function useDescriptionElementId(description: string | undefined, id: string) {
  return description ? `description-${id}` : undefined
}

export function getItemValue (item: V.ContextMenu.Checkbox) {
  if (typeof item.value === 'boolean') return item.value
  else return item.value.get()
}
export function useSubmit (onSubmit: () => Promise<void>, onError = () => {}) {
  const isSubmitting = ref(false)

  const submit = async () => {
    if (isSubmitting.value) return

    isSubmitting.value = true
 
    try {
      await onSubmit()
    } catch (e) {
      useErrorToast(e)

      onError()
    } finally {
      isSubmitting.value = false
    }
  }

  return {
    submit,
    isSubmitting
  }
}
//TODO the return type may be inferred from the function
export const addUniqueIds = <T, K extends string = 'id'>(array: T[], idField: K = 'id' as K): (T & Record<K, string>)[] => {
  return array.map((item) => ({
    ...item,
    [idField]: getUUID()
  })) as (T & Record<K, string>)[]
}
